import React, { FC, useDebugValue, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import useBriteVerifyEmailApi from "../../../hooks/briteVerifyEmail";
import useDebounce from "../../../hooks/useDebounce";
import useDumbleData from "../../../hooks/useDumbleData";
import useVerifyEmail from "../../../hooks/useVerifyEmail";
import dumbleDataProps from "../../../interfaces/dumbleDataProps";
import refProps from "../../../interfaces/refProps";
import registerProps from "../../../interfaces/registerProps";
import validate from "../../../utils/validate";
import styles from "./MyInfo.module.scss";
import baselineMsg from '../../../interfaces/baselineMsgConfig';

interface MyInfoProps {}

const MyInfo = (props: refProps) => {
  const location = useLocation<any>();

  const { referrer, chat, reg } = props;
  const firstNameDebouncer = useDebounce();
  const lastNameDebouncer = useDebounce();
  const emailDebouncer = useDebounce();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState({
    value:
      location.state?.firstName?.value === undefined
        ? ""
        : location.state.firstName.value,
    error: "",
    touched: location.state?.firstName?.value === undefined ? false : true,
  });

  const [lastName, setLastName] = useState({
    value:
      location.state?.lastName?.value === undefined
        ? ""
        : location.state.lastName.value,
    error: "",
    touched: location.state?.lastName?.value === undefined ? false : true,
  });

  const [email, setEmail] = useState({
    value:
      location.state?.email?.value === undefined
        ? ""
        : location.state.email.value,
    error: "",
    touched: location.state?.email?.value === undefined ? false : true,
  });

  const [status, setStatus] = useState(200);
  let history = useHistory();

  const [briteVerify] = useBriteVerifyEmailApi();
  const [verifyEmail] = useVerifyEmail();

  const dumbleDataHook = useDumbleData;
  let dumbledata: dumbleDataProps;

  useEffect(() => {
    if (referrer != "cptk") {
      let pageData = {
        name: "MyScholastic:Registration:My Info",
        type: "Registration",
        siteSectionLevel1: "MyScholastic:Registration:My Info",
      };
      dumbledata = dumbleDataHook("page", pageData);
    } else {
      let pageData = {
        name: "MyScholastic:Registration:Chairperson:My Info",
        type: "Registration",
        siteSectionLevel1: "MyScholastic:Registration:Chairperson:My Info",
      };
      dumbledata = dumbleDataHook("page", pageData);
    }
  }, []);

  const submitMyInfo = async () => {
    if (email.error !== "") {
      return;
    }
    setIsLoading(true);
    const { data, error } = await verifyEmail(email.value, "");
    if (error != null) {
      setIsLoading(false);
      if (error.status == 404) {
        history.push({
          pathname: "/register/register-password.html",
          state: {
            firstName: firstName.value.trim(),
            lastName: lastName.value.trim(),
            email: email.value.trim(),
          },
        });
      } else {
        setIsLoading(false);
        setStatus(error.status);
      }
    } else if (data != null) {
      setIsLoading(false);
      setStatus(200);
      if (data.status == "SUCCESS")
        setEmail(handleInput("error", "Email already exists", email));
    }
  };

  const briteVerifyEmail = async () => {
      setIsLoading(true);
      const { data, error } = await briteVerify(email.value);
      if (error) {
        setIsLoading(false);
        console.log("Error with brite verify");
        setStatus(error.status);
      } else if (data != null) {
        setIsLoading(false);
        setStatus(200);
        if (data.status == "invalid") {
          setEmail(
            handleInput(
              "error",
              baselineMsg('emailInactive', referrer),
              email
            )
          );
        } else {
          submitMyInfo();
        }
      }
    
  };

  useEffect(() => {
    const listener = (event: { code: string }) => {
      
      if (event.code === "Enter" && email.error == "" &&
      firstName.error == "" &&
      lastName.error == "" &&
      status == 200 && !isLoading) {
        briteVerifyEmail();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [firstName, lastName, email, isLoading]);

  const handleInput = (type: string, input: any, field: object) => {
    const newField: any = { ...field };
    newField[type] = input;
    return newField;
  };

  useEffect(() => {
    if (
      email.error == "" &&
      firstName.error == "" &&
      lastName.error == "" &&
      status == 200
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [email, firstName, lastName, status]);

  useEffect(() => {
    const timeout = emailDebouncer(
      setEmail.bind(
        briteVerify,
        handleInput(
          "error",
          validate(email.value, ["required", "emailFormat"]),
          email
        )
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [email.value]);

  useEffect(() => {
    const timeout = firstNameDebouncer(
      setFirstName.bind(
        MyInfo,
        handleInput(
          "error",
          validate(firstName.value, ["required", "nameMinReq", "unicodeCheck"]),
          firstName
        )
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [firstName.value]);

  useEffect(() => {
    const timeout = lastNameDebouncer(
      setLastName.bind(
        MyInfo,
        handleInput(
          "error",
          validate(lastName.value, ["required", "nameMinReq", "unicodeCheck"]),
          lastName
        )
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [lastName.value]);

  return (
    <div className={styles.MyInfo} data-testid="MyInfo">
      {isLoading && (
        <div>
          <div className={styles.spinnerModal}>
            <div className={styles.spinnerPosition}>
              <Spinner />
            </div>
          </div>
        </div>
      )}

      <div className={styles.myInfoContainer}>
        <div className={styles.header}>My Info</div>
        <div>
          <input
            className={`${styles.inputGrey} ${
              firstName.error == "" || !firstName.touched
                ? ""
                : styles.errorField
            }`}
            id="first-name"
            aria-label="enter first name"
            name="firstName"
            autoFocus
            value={firstName.value}
            placeholder="First Name"
            onSelect={() =>
              setFirstName(handleInput("touched", true, firstName))
            }
            onChange={(e) =>
              setFirstName(handleInput("value", e.target.value, firstName))
            }
            maxLength={30}
          />
          <div
            data-testid="firstNameErr"
            className={`${
              firstName.error != "" && firstName.touched
                ? styles.errorMessage
                : styles.disable
            }`}
          >
            {firstName.error}
          </div>
        </div>
        <div>
          <input
            className={`${styles.inputGrey} ${
              lastName.error == "" || !lastName.touched ? "" : styles.errorField
            }`}
            id="last-name"
            aria-label="enter last name"
            name="lastName"
            value={lastName.value}
            placeholder="Last Name"
            onSelect={() => setLastName(handleInput("touched", true, lastName))}
            onChange={(e) =>
              setLastName(handleInput("value", e.target.value, lastName))
            }
            maxLength={30}
          />
          <div
            data-testid="lastNameErr"
            className={`${
              lastName.error != "" && lastName.touched
                ? styles.errorMessage
                : styles.disable
            }`}
          >
            {lastName.error}
          </div>
        </div>
        <div>
          <input
            className={`${styles.inputGrey} ${
              email.error == "" || !email.touched ? "" : styles.errorField
            }`}
            id="email"
            aria-label="enter email"
            name="email"
            value={email.value}
            placeholder="Email"
            onSelect={() => setEmail(handleInput("touched", true, email))}
            onChange={(e) => {
              if (!isLoading)
                setEmail(handleInput("value", e.target.value, email));
            }}
          />
          <div
            data-testid="emailBriteErr"
            className={`${
              email.error != "" &&
              email.error != "Email already exists" &&
              email.touched
                ? styles.errorMessage
                : styles.disable
            }`}
          >
            {email.error}
          </div>
          <div
            data-testid="serverErr"
            className={`${
              status != 200 ? styles.errorMessage : styles.disable
            }`}
          >
            Something went wrong. Please try again later.
          </div>
          <div
            data-testid="emailVerifiedErr"
            className={`${
              email.error == "Email already exists" && email.touched
                ? styles.alreadyExistsError
                : styles.disable
            }`}
          >
            We're sorry, but an account already exists with that email address.
            Please sign in or use a different email address.
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <SubmitButton
            outlined={false}
            isDisabled={buttonDisabled}
            name={"Next"}
            submitFunction={briteVerifyEmail}
            id={"register-myinfo-button"}
          />
        </div>
      </div>
    </div>
  );
};

export default MyInfo;
