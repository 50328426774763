import {useContext} from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useRegisterApi = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('POST');

  const registerUser = async (body: any, ref: string) => {
    return await makeApiRequest(`${apiBase}/api/register?ref=${ref}`, body);
  };

  return [registerUser];
};

export default useRegisterApi;