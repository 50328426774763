import React, { FC, useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import useDebounce from '../../../hooks/useDebounce';
import refProps from '../../../interfaces/refProps';
import styles from './RegisterPassword.module.scss';
import validate from '../../../utils/validate';
import checkMark from '../../../styles/images/check-mark.svg';
import eyeHide from '../../../styles/images/eye-hide.svg';
import eyeShow from '../../../styles/images/eye-show.svg';
import bullet from '../../../styles/images/filled-circle.svg';
import { useHistory, useLocation } from 'react-router-dom';
import registerProps from '../../../interfaces/registerProps';
import dumbleDataProps from '../../../interfaces/dumbleDataProps';
import useDumbleData from '../../../hooks/useDumbleData';
import useRegisterApi from '../../../hooks/useRegisterApi';
import buildRegisterBody from '../../../utils/register-body-builder';


const RegisterPassword = (props: refProps) => {
  const { referrer, chat, reg } = props;
  
  let history = useHistory();
  const passwordDebouncer = useDebounce();
  const confirmedPasswordDebouncer = useDebounce();
  const [show, setShow] = useState(false);

  const ref = useRef(null);
  const location = useLocation<registerProps>();
  const [firstName, setFirstName] = useState(location.state?.firstName);
  const [lastName, setLastName] = useState(location.state?.lastName);
  const [email, setEmail] = useState(location.state?.email);
  const [status, setStatus] = useState(0);
  const [disabled, setDisabled] = useState(true); //button disabled
  const [isLoading, setIsLoading] = useState(false); // spinner
  const [showPassword, setShowPassword] = useState(false);
  const [tos, setTos] = useState(false);
  const [target, setTarget] = useState(null);
  const [registerUser] = useRegisterApi();
  const scholasticPrivacyLink: string = 'http://www.scholastic.com/privacy.htm';
  const scholasticTermsLink: string = 'http://www.scholastic.com/terms.htm';

  const [password, setPassword]= useState({
    value: '',
    error: 'This field is required',
    touched: false
  })

  const [confirmedPassword, setConfirmedPassword]= useState({
    value: '',
    error: '',
    touched: false
  })

  const handleShowPassword = ()=>{
    setShowPassword(!showPassword);
  }

  const dumbleDataHook = useDumbleData;
  let dumbledata: dumbleDataProps;

  useEffect(()=> {
    if(referrer != "cptk"){
      let pageData = {
        name: 'MyScholastic:Registration:My Password',
        type: 'Registration',
        siteSectionLevel1: 'MyScholastic:Registration:My Password'
      };
      dumbledata = dumbleDataHook('page', pageData);
    } else {
      let pageData = {
        name: 'MyScholastic:Registration:Chairperson:My Password',
        type: 'Registration',
        siteSectionLevel1: 'MyScholastic:Registration:Chairperson:My Password'
      };
      dumbledata = dumbleDataHook('page', pageData);
    }
  }, []);

  useEffect(()=>{
    const timeout = passwordDebouncer(
      setPassword.bind(
        RegisterPassword,
        handleInput('error', (validate(password.value, ['password'])), password)
      ),
      300
    )
    return () => clearTimeout(timeout);
  },[password.value]);

  useEffect(()=>{
    const timeout = confirmedPasswordDebouncer(
      checkPassword,
      300
    )
    return () => clearTimeout(timeout);
  },[password.value, confirmedPassword.value]);

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  } 

  const checkPassword = (()=>{
    if ((password.value == confirmedPassword.value || !confirmedPassword.touched)){
      setConfirmedPassword(handleInput('error', "", confirmedPassword));
    } else if ((password.value !== confirmedPassword.value && confirmedPassword.touched)){
      setConfirmedPassword(handleInput('error', "Your password did not match. Try again.", confirmedPassword));
    }
  })

  useEffect(()=>{
    setDisabled(
      password.value !== confirmedPassword.value ||
      password.error !== "" ||
      confirmedPassword.error !== "" || 
      tos === false
    );
  },[password, confirmedPassword, tos]);

  const handleInput = ((type: string, input: any, field: object) => {
    const newField: any = {...field};
    newField[type] = input;
    return newField;
  })

  const redirectMyRole = () => {
    if(referrer == "cptk" || referrer == "lp" || referrer == "ror"){
      register();
    } else {
      history.push({
        pathname: '/register/my-role.html',
        state: {
          password: password.value,
          firstName: firstName,
          lastName: lastName,
          email: email
        }
      });
    }
  }


  const register = async () => {
    const {data, error} = await registerUser(buildRegisterBody(firstName,lastName,password.value,email, "", "parent"), referrer);
    if(error != null){
      console.log(error);
      setStatus(error.status);
    } else {
      console.log(data);
      setStatus(200);
      history.push({
        pathname: '/register/welcome.html',
        state: {user: data, username: email, password: password}
      });
    }
  }
  
  const redirectMyInfo = () => {
    history.push({
      pathname: '/register/my-info.html',
      state: {email: {value: email, error: '', touched: true},
      firstName: {value: firstName, error: '', touched: true},
      lastName: {value: lastName, error: '', touched: true}}
    });
  }

  const openToggle = async (event: any) =>{
    event.persist();
    await timeout(300);
    setShow(true);
    setTarget(event.target);
  }

  const closeToggle = () => {
    setShow(false);
  }

  const handleTos = () => {
    setTos(!tos);
  }

  return (
  <div className={styles.RegisterPassword} data-testid="RegisterPassword">
    <div className={styles.headerText}>My Password</div>
    <div className={styles.inputContainer}>
      <div className={styles.passwordContainer}>
        <i className={styles.eye}>
          <img 
            tabIndex={0}
            alt="show or hide password"
            src={showPassword ? eyeHide : eyeShow} 
            onClick={handleShowPassword}
            onKeyPress={(e) => {if(e.key === 'Enter' || e.key === ' '){handleShowPassword()}}}
          />
        </i>
        <input 
          autoFocus
          id="register-new-password"
          aria-label="password"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          className={`${styles.inputGrey} ${password.error === '' || !password.touched ? '' : styles.errorField}`}
          onSelect={()=>setPassword(handleInput('touched', true, password))}
          onChange={(e) => setPassword(handleInput('value', e.target.value, password))}
          onFocus={(e)=>openToggle(e)}
          onBlur={()=>closeToggle()}
        />
      </div>
        <input 
          id="register-confirm-password"
          aria-label="confirm password"
          type="password"
          placeholder="Confirm Password"
          className={`${styles.inputGrey} ${confirmedPassword.error === '' || !confirmedPassword.touched ? '' : styles.errorField}`}
          onSelect={()=>setConfirmedPassword(handleInput('touched', true, confirmedPassword))}
          onChange={(e) => setConfirmedPassword(handleInput('value', e.target.value, confirmedPassword))}
        />
        <div data-testid="doesNotMatchErr" className={`${confirmedPassword.error !== '' ? styles.errorMessage : styles.disable}`}>{confirmedPassword.error}</div> {/* show this error if they're not the same passwords */}
    </div>
    <div className={styles.passReq}>
      Your password must be at least seven characters long and include at least one letter and one number. It is case sensitive and special characters are allowed.
    </div>
    <div className={styles.tosContainer}>
      <input
        type="checkbox"
        className={styles.tosCheck}
        onChange={() => handleTos()}
      />
      <div className={styles.tosText}>
        I HAVE READ AND AGREE TO SCHOLASTIC'S <a href={scholasticPrivacyLink} target="_blank" rel="noopener noreferrer">PRIVACY POLICY</a> AND <a href={scholasticTermsLink} target="_blank" rel="noopener noreferrer">TERMS OF USE</a>.
      </div>
    </div>
    <div className={styles.buttonContainer}>
      <button
        id="register-password-back"
        aria-label="back password"
        className = {`${styles.button} ${styles.purple} ${disabled ? styles.disabled : ''}`}
        onClick={redirectMyInfo}
        >
          PREVIOUS
      </button>
      <button
        id="register-password-next"
        aria-label="next button"
        disabled={disabled}
        className = {`${styles.button} ${styles.purple} ${disabled ? styles.disabled : ''}`}
        onClick={redirectMyRole}
        onKeyPress={(e) => {if(e.key === 'Enter'){e.preventDefault(); redirectMyRole();}}}>
          NEXT
      </button>
    </div>

    <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref.current}
        containerPadding={20}>
        <Popover id="popover-contained" className={styles.popup}>
          <Popover.Content>
            <div className={styles.pleaseText}>Please enter a password that meets all criteria</div>
            <div className={styles.popOverText}><div className={styles.createText}>To create a strong password use</div>
              <div className={styles.listItem}>
                <i className={`${validate(password.value, ['passwordMin']) === '' ? styles.checkmark : styles.bullet}`}><img 
                src={validate(password.value, ['passwordMin']) === '' ? checkMark : bullet} alt="password minimum length requirement checkmark"
                /></i>
                <div className={`${validate(password.value, ['passwordMin']) === '' ? styles.success : ''}`}>7 or more characters</div>
              </div>
              <div className={styles.listItem}>
                <i className={`${validate(password.value, ['passwordNumLetter']) === '' ? styles.checkmark : styles.bullet}`}><img 
                src={validate(password.value, ['passwordNumLetter']) === '' ? checkMark : bullet} alt="password number and letter requirement checkmark"
                /></i>
                <div className={`${validate(password.value, ['passwordNumLetter']) === '' ? styles.success : ''}`}>At least 1 letter and 1 number</div>
              </div>
              {/* // Suppressed until we communicate with other teams
              <div className={styles.listItem}>
                <i className={`${validate(password.password, ['oneUpperOneLower']) == '' ? styles.checkmark : styles.bullet}`}><img 
                src={validate(password.password, ['oneUpperOneLower']) == '' ? checkMark : bullet}
                /></i>
                <div className={`${validate(password.password, ['oneUpperOneLower']) == '' ? styles.success : ''}`}>Upper and lowercase letters</div>
              </div>
              <div className={styles.listItem}>
                <i className={`${validate(password.password, ['passwordSpecial']) == '' ? styles.checkmark : styles.bullet}`}><img 
                src={validate(password.password, ['passwordSpecial']) == '' ? checkMark : bullet}
                /></i>
                <div className={`${validate(password.password, ['passwordSpecial']) == '' ? styles.success : ''}`}>Special characters</div>
              </div>*/}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
  </div>
);}

export default RegisterPassword;
