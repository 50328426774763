import React, {useState,useEffect } from "react";
import refProps from "../../../interfaces/refProps";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./MyKids.module.scss";
import MyKid from "./MyKid";
import {child,children} from "../../../interfaces/child";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import buildRegisterBody from '../../../utils/register-body-builder';
import useRegisterApi from '../../../hooks/useRegisterApi';
import useAddKid from "../../../hooks/useAddKidApi";
import useDumbleData from "../../../hooks/useDumbleData";
import dumbleDataProps from "../../../interfaces/dumbleDataProps";


const MyKids = (props: refProps) => {
  const { referrer } = props;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const location = useLocation<any>();
  const [status, setStatus] = useState(200);
  const [firstName, setFirstName] = useState(location.state?.firstName);
  const [lastName, setLastName] = useState(location.state?.lastName);
  const [email, setEmail] = useState(location.state?.email);
  const [password, setPassword] = useState(location.state?.password);
  const [registerUser] = useRegisterApi();
  const [addKid] = useAddKid();
  const dumbleDataHook = useDumbleData;
  let dumbledata: dumbleDataProps;

  useEffect(()=> {
    let pageData = {
      name: 'MyScholastic:Registration:My Kids',
      type: 'Registration',
      siteSectionLevel1: 'MyScholastic:Registration:My Kids'
    };
    dumbledata = dumbleDataHook('page', pageData);
  }, []);
  
  const [children, setChildren] = useState<children>([
    { firstName: "", lastName: "", grade: "" }
  ]);

  let history = useHistory();

  const submitKids = async () => {
    const {data, error} = await registerUser(buildRegisterBody(firstName,lastName,password,email, "", "parent"), referrer);
    if(error != null){
      console.log(error);
      setStatus(error.status);
    } else {
      console.log(data);
      setStatus(200);
      registerKids();
    }
  }

  const registerKids = async ()=>{
    const {data, error} = await addKid(children);
      if(error!=null){
        console.log(error);
        setStatus(error.status);
      } else {
        setStatus(200);
        history.push({
          pathname: "/register/welcome.html",
          state: {user: data, password: password, username: email}
        });
      }
  }


  const addKidForm = () => {
    setChildren([...children, { firstName: "", lastName: "", grade: "" }]);
  };

  const updateChildren = (index: number, child: child) => {
    const newChildren = [...children];
    newChildren[index] = child;
    setChildren(newChildren);
  };

  const deleteChildForm = (index: number) => {
    const newChildren = [...children];
    newChildren.splice(index, 1);
    setChildren(newChildren);
  };

  const gotToPrevious = () => {
    history.push({
      pathname: "/register/register-password.html",
    });
  };

  const skipToWelcome = async () => {
    const {data, error} = await registerUser(buildRegisterBody(firstName,lastName,password,email, "", "parent"), referrer);
    if(error != null){
      console.log(error);
      setStatus(error.status);
    } else {
      setStatus(200);
      console.log(data);
      history.push({
        pathname: "/register/welcome.html",
        state: {user: data, password: password, username: email}
      });
    }
  };

  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (event.code === "Enter" && !buttonDisabled) {
        submitKids();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [buttonDisabled])

  return (
    <div className={styles.KidInfo} data-testid="KidInfo">
      <div>
        <div className={styles.header}>My Kids</div>
        <div className={styles.studentFormDisclaimer}>
          We take your child's information very seriously. This information is
          used to help us recommend appropriate books for your child.
        </div>
        <div className={styles.skipStepLink} onClick={() => skipToWelcome()}>
          SKIP THIS STEP
        </div>
        {children.map((child, key) => (
          <MyKid
            setButtonDisabled={setButtonDisabled}
            child_id={key}
            children={children}
            updateChildren={updateChildren}
            deleteChildForm={deleteChildForm}
          />
        ))}
        {children.length < 10 && <div className={styles.btnKidAdd} onClick={() => addKidForm()}>
          + ADD A KID
        </div>}
        <div data-testid="serverErr" className={`${status != 200 ? styles.errorMessage : styles.disable}`}>Something went wrong. Please try again later.</div>
        <div className={styles.buttonContainer}>
          <SubmitButton outlined={true} name={"Previous"} submitFunction={gotToPrevious} id={"previous-button"}
          />
          <SubmitButton outlined={false} isDisabled={buttonDisabled} name={"Next"} submitFunction={submitKids} id={"register-kidinfo-button"}
          />
        </div>
      </div>
    </div>
  );
};

export default MyKids;
